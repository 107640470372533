// html,
// body,
// ngx-app,
// ngx-pages,
// ngx-one-column-layout,
// nb-layout {
//     height: 100%;
//     width: 100%;
//     margin: 0;
//     padding: 0;
//     overflow: hidden;
// }
.layout,
.content {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

ngx-ai,
ngx-gans {
    height: 100%;

}

.user-name {
    display: none;
}

nb-layout-column {
    padding: 20px !important;
}

button:focus,
button:active {
    outline: 1px solid #ccc !important;
}

.layout-container {
    height: calc(100% - 76px);
}

.columns {
    height: calc(100% - 76px);
}

nb-layout-column {
    height: 100%;
    overflow: auto;
}

.header-container .logo {
    padding: 0 !important;
}

h1 {
    margin: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
    max-height: 44px;
}

/* fxLayout="row" */
.fxLayoutRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
}

.fxLayoutRowEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;
    width: 100%;
}

/* fxLayout="column" */
.fxLayoutColumn {
    display: flex;
    flex-direction: column;
}

/* fxLayoutAlign="start center" */
.fxLayoutAlignStartCenter {
    align-items: flex-start;
    justify-content: center;
}

/* fxLayoutAlign="space between" */
.fxLayoutRowSpaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* fxLayoutGap="16px" */
.fxLayoutGap16 {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    /* Negative margin to account for gaps */
}

.fxLayoutGap16 > * {
    margin: 8px;
    /* Add gap between elements */
}

.nb-theme-dark input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #0e1223;
    border-radius: 4px;
    background-color: #12162a;
}

.nb-theme-cosmic .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid rgb(68 49 147 / 47%);
    background: rgb(255 255 255 / 2%);
    color: #9db0c3;
    padding: 1.25rem;
    border-radius: 5px;
}

.nb-theme-dark .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid rgba(54, 48, 77, 0.47);
    background: rgb(255 255 255 / 2%);
    color: #d3dde8;
    padding: 1.25rem;
    border-radius: 5px;
}

.p-breadcrumb {
    background: rgb(255 255 255 / 10%);
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 1rem;
}

.nb-theme-cosmic .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text,
.nb-theme-cosmic .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
.nb-theme-cosmic .p-breadcrumb ul li.p-breadcrumb-chevron {
    color: #feffff;
}

.nb-theme-dark .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text,
.nb-theme-dark .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
.nb-theme-dark .p-breadcrumb ul li.p-breadcrumb-chevron {
    color: #feffff;
}

.card {
    background-color: rgb(255 255 255 / 15%);
}

.nb-theme-cosmic .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.nb-theme-cosmic .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #ffffff;
}

.nb-theme-dark .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.nb-theme-dark .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #ffffff;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: rgb(233 236 239 / 14%);
}

.nb-theme-dark .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.nb-theme-cosmic .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #ffffff;
}

.nb-theme-dark .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.nb-theme-cosmic .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #ffffff;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #eff6ff11;
}

.p-megamenu .p-megamenu-panel {
    background: rgb(255 255 255 / 3%);
    color: #495057;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-megamenu .p-megamenu-submenu-header {
    background: rgb(255 255 255 / 3%);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgb(233 236 239 / 16%);
}

.nb-theme-dark .p-megamenu .p-menuitem-link .p-menuitem-text,
.nb-theme-cosmic .p-megamenu .p-menuitem-link .p-menuitem-text,
.nb-theme-dark .p-megamenu .p-menuitem-link,
.nb-theme-cosmic .p-megamenu .p-menuitem-link {
    color: #ffffff;
}

.nb-theme-dark .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.nb-theme-cosmic .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.nb-theme-dark .p-megamenu .p-megamenu-submenu-header,
.nb-theme-cosmic .p-megamenu .p-megamenu-submenu-header {
    color: #ffffff;
}

.p-megamenu {
    border: 1px solid rgb(222 226 230 / 13%);
    background: rgb(248 249 250 / 95%);
}

.nb-theme-dark .p-megamenu {
    border: 1px solid rgb(222 226 230 / 13%);
    background: rgb(248 249 250 / 10%);
}

.nb-theme-cosmic .p-megamenu {
    border: 1px solid rgb(222 226 230 / 13%);
    background: rgb(248 249 250 / 10%);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.nb-theme-cosmic .p-splitter,
.nb-theme-dark .p-splitter {
    border: 1px solid rgb(222 226 230 / 18%);
    background: rgb(255 255 255 / 8%);
    color: #ffffff;
}

.nb-theme-default {
    nb-icon[ng-reflect-config="film-outline"] {
        color: #17a027 !important;
    }

    nb-icon[ng-reflect-config="settings"] {
        color: #ff5700 !important;
    }

    nb-icon[ng-reflect-config="edit-2-outline"] {
        color: #0099ff !important;
    }

    nb-icon[ng-reflect-config="keypad-outline"] {
        color: #7e0b80 !important;
    }

    nb-icon[ng-reflect-config="browser-outline"] {
        color: #2062fa !important;
    }

    nb-icon[ng-reflect-config="message-circle-outline"] {
        color: #0ea803 !important;
    }

    nb-icon[ng-reflect-config="layout-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="map-outline"] {
        color: #045f42 !important;
    }

    nb-icon[ng-reflect-config="pie-chart-outline"] {
        color: #fe9000 !important;
    }

    nb-icon[ng-reflect-config="text-outline"] {
        color: #031e2f !important;
    }

    nb-icon[ng-reflect-config="grid-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="shuffle-2-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="lock-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="email-outline"] {
        color: #042235 !important;
    }
}

.nb-theme-cosmic {
    nb-icon[ng-reflect-config="film-outline"] {
        color: #17a027 !important;
    }

    nb-icon[ng-reflect-config="settings"] {
        color: #ff5700 !important;
    }

    nb-icon[ng-reflect-config="edit-2-outline"] {
        color: #0099ff !important;
    }

    nb-icon[ng-reflect-config="keypad-outline"] {
        color: #7e0b80 !important;
    }

    nb-icon[ng-reflect-config="browser-outline"] {
        color: #2062fa !important;
    }

    nb-icon[ng-reflect-config="message-circle-outline"] {
        color: #0ea803 !important;
    }

    nb-icon[ng-reflect-config="layout-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="map-outline"] {
        color: #045f42 !important;
    }

    nb-icon[ng-reflect-config="pie-chart-outline"] {
        color: #fe9000 !important;
    }

    nb-icon[ng-reflect-config="text-outline"] {
        color: #031e2f !important;
    }

    nb-icon[ng-reflect-config="grid-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="shuffle-2-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="lock-outline"] {
        color: #036aa8 !important;
    }

    nb-icon[ng-reflect-config="email-outline"] {
        color: #65b1e1 !important;
    }
}

.menu-sidebar .scrollable {
    padding-top: 1px !important;
}

.e-commerce-dashboard {


    a {
        background: rgba(69, 145, 210, 0.5411764706) !important;
        color: #fff !important;

        &.active {
            background: rgb(36 154 255 / 54%) !important;
            color: #fff !important;
        }

        &:hover {
            background: rgba(7, 136, 249, 0.75) !important;
            color: #fff !important;
        }
    }

    nb-icon {
        color: #fff !important;
    }
}

.iot-dashboard {

    a {
        background: #4591d28a !important;
        color: #fff !important;

        &.active {
            background: rgb(36 154 255 / 54%) !important;
            color: #fff !important;
        }

        &:hover {
            background: rgba(7, 136, 249, 0.75) !important;
            color: #fff !important;
        }
    }

    nb-icon {
        color: #fff !important;
    }
}

.features {
    background: #b8c0c6;
    color: #fff !important;
}

.logo {
    font-weight: bold;
    color: #8598b1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0 0 0 / 15%);
}

button.select-button {
    border: solid 1px #cfcfcf !important;

    span {
        color: #959292 !important;
    }
}

nb-option {
    color: #959292 !important;
}

nb-option.selected {
    background-color: #eee !important;
    color: #959292 !important;
}

nb-user .user-name {
    color: #959292 !important;
}

.nb-theme-default nb-action nb-icon[ng-reflect-config=email-outline] {
    color: #abb9c2 !important;
}

nb-option-list.size-medium {
    width: 125px !important;
}

nb-menu .menu-item > .menu-items > .menu-item a:hover {
    background-color: rgb(0 128 0 / 15%);
}

nb-menu .menu-item a:hover {
    background-color: rgb(0 128 0 / 15%);
}

nb-menu .menu-item {
    border-bottom: 1px solid #f3f3f3;
}

li.features {
    text-align: center;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(166 175 182);
    font-size: 16px;
    font-weight: bold;
}

.nb-theme-cosmic .menu-item.features span,
.nb-theme-dark .menu-item.features span {
    text-align: center;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(29 45 57);
    font-size: 16px;
    font-weight: bold;
}

.menu-item.features {
    border-top: solid 1px #d4dbe0;
    border-bottom: solid 1px #91969b;
    justify-content: center;
    display: flex;
    padding: 5px;

    span {
        text-align: center;
        align-items: center;
        justify-content: center;
        letter-spacing: 2px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgb(166 175 182);
        font-size: 16px;
        font-weight: bold;
    }
}

.logo {
    border-left: none !important;
}

.menu-item.e-commerce-dashboard {
    border-top: solid 1px #5b99cb !important;
}

.nb-theme-cosmic nb-menu .menu-item {
    border-bottom: 1px solid #2b2b4f;
}

.nb-theme-dark nb-menu .menu-item {
    border-bottom: 1px solid #1e2935;
}

.menu-item.e-commerce-dashboard {
    border-bottom: 1px solid #56a3e7 !important;
    border-top: 1px solid #6198cf !important;
}

.menu-item.iot-dashboard.menu-item {
    border-bottom: 1px solid #7f8b98 !important;
    border-top: 1px solid #badcff !important;
}

.nb-theme-dark .menu-item.iot-dashboard.menu-item {
    border-bottom: 1px solid #1b5895 !important;
    border-top: 1px solid #24466b !important;
}

.nb-theme-cosmic .menu-item.e-commerce-dashboard {
    border-bottom: 1px solid #6621a5 !important;
    border-top: 1px solid #1b5895 !important;
}

.nb-theme-cosmic .e-commerce-dashboard a {
    background: rgb(93 43 168) !important;
}

.nb-theme-cosmic .iot-dashboard a.active {
    background: rgb(92 43 167) !important;
    color: #fff !important;
}

.nb-theme-cosmic .features {
    background: #31317e;
    color: #fff !important;
}

.nb-theme-cosmic .iot-dashboard a {
    background: rgb(84 69 210 / 54%) !important;
    color: #fff !important;
    border-bottom: solid 1px #443c9b !important;
}

.nb-theme-cosmic .menu-item.iot-dashboard.menu-item {
    border-bottom: 1px solid #4d1b95 !important;
    border-top: 1px solid #323259 !important;
}

.nb-theme-cosmic .menu-item.features {
    border-top: solid 1px #4141a1;
    border-bottom: solid 1px #475c6d;
}

.nb-theme-dark .menu-item.e-commerce-dashboard {
    border-bottom: 1px solid #4188c6 !important;
    border-top: 1px solid #1b5895 !important;
}

.nb-theme-dark .menu-sidebar .scrollable {
    padding-top: 7px !important;
}

.nb-theme-dark .features {
    background: #121725;
    color: #fff !important;
}

nb-user {
    cursor: unset !important;
}

.nb-theme-cosmic .p-card,
.nb-theme-cosmic .xai-technique-item {
    background-color: #380444 !important;
    color: #eef1f5;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    p {
        color: #eef1f5 !important;
    }
}

.nb-theme-dark .p-card,
.nb-theme-dark .xai-technique-item {
    background-color: #042044 !important;
    color: #eef1f5;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    p {
        color: #eef1f5 !important;
    }
}

.nb-theme-dark .contactForm,
.nb-theme-cosmic .contactForm {
    background: rgb(255 255 255 / 2%) !important;
    color: #fff;
}

.nb-theme-dark .about-section,
.nb-theme-cosmic .about-section {
    background: rgb(255 255 255 / 2%) !important;
    color: #fff;
}

.nb-theme-dark .contactForm p,
.nb-theme-dark .contactForm h3,
.nb-theme-dark .contactForm li div,
.nb-theme-cosmic .contactForm p,
.nb-theme-cosmic .contactForm h3,
.nb-theme-cosmic .contactForm li div {
    background: rgb(255 255 255 / 2%) !important;
    color: #fff !important;
}

.nb-theme-dark .about-section p,
.nb-theme-dark .about-section h3,
.nb-theme-dark .about-section li div,
.nb-theme-cosmic .about-section p,
.nb-theme-cosmic .about-section h3,
.nb-theme-cosmic .about-section li div {
    background: rgb(255 255 255 / 2%) !important;
    color: #fff !important;
}

.ai-components {
    position: relative;
}

.ai-components a::after {
    content: "new";
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgba(1, 142, 48, 0.02);
    z-index: 1;
    top: 11px;
    right: 45px;
    border-radius: 5px;
    padding: 0 5px;
    border: solid 1px #9fc6ee;
    color: #007f17;
    font-size: 10px;
    text-transform: uppercase;
    pointer-events: none;
}

.left.compacted .ai-components a::after {
    right: 15px;
    top: -4px;
    color: #e2740e;
    border: none;
    font-weight: bold;
    pointer-events: none;
}

@media (max-width: 500px) {
    .tooltip-icon {
        display: none;
    }

    ngx-about nb-card {
        width: 90% !important;
        margin: auto !important;
    }

    .about-section li .title,
    .about-section li .title strong {
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 100%;
    }

    .about-section li {
        flex-direction: column;
    }
}
